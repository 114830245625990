export default [
  {
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'HomeIcon',

  },
  {
    title: 'Products',
    route: 'products',
    icon: 'PackageIcon',
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    route: 'users',

  },
  {
    title: 'Shipping & Handling',
    icon: 'TruckIcon',
    children: [
      {
        title: ' Zones',
        route: 'shipping-zones',

      },
      {
        title: ' Companies',
        route: 'shipping-companies',

      },

    ],
  },

  {
    title: 'Promo Codes',
    route: 'promoCode',
    icon: 'TagIcon',
    action: 'browes',
    resource: 'offers',
  },

  {
    title: 'Partners',
    icon: 'UserIcon',
    action: 'browes',
    children: [
      {
        title: 'Partners',
        route: 'partners',
        action: 'browes',

      },
      {
        title: ' Branches',
        route: 'partners-branches',
      },
      {
        title: ' Employees',
        route: 'partners-employees',
      },

    ],
  },
  {
    title: 'Countries',
    icon: 'CompassIcon',
    route: 'countries',
  },
  {
    title: 'Governorates',
    icon: 'GlobeIcon',
    route: 'governments',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Social Contacts',
        route: 'social-contacts',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'Contact Categories',
        route: 'contact-categories',
        action: 'browes',
        resource: 'providers',
      },
      {
        title: 'User Messages ',
        route: 'user-messages',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'FAQS',
        route: 'faqs',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'App Version',
        route: 'app-version',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'App Data',
        route: 'app-data',
        action: 'browes',
        resource: 'configs',
      },
    ],
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'ShoppingBagIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Banners',
    route: 'banners',
    icon: 'ImageIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Sliders',
    icon: 'VideoIcon',
    action: 'browes',
    children: [
      {
        title: ' Sliders',
        route: 'sliders',

      },
      {
        title: 'Arrange',
        route: 'arrange-sliders',
      }],
  },
  {
    title: 'Campaigns',
    icon: 'LayersIcon',
    children: [
      {
        title: ' Types',
        route: 'campaign-types',

      },
      {
        title: 'Arrange',
        route: 'arrange',
      },
      {
        title: 'Normal Campaigns',
        route: 'campaigns',
      },
      {
        title: 'Offline Campaigns',
        route: 'offline-campaigns',
      },
      {
        title: 'Watch Campaigns',
        route: 'watch-campaigns',
      },
      {
        title: 'Flash Campaigns',
        route: 'flash-campaigns',
      },
      {
        title: 'Action Campaigns',
        route: 'action-campaigns',
      },

    ],
  },

  {
    title: 'Product Ads',
    route: 'product-ads',
    icon: 'YoutubeIcon',
    action: 'browes',
    resource: 'configs',
  },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'AwardIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Gifts',
    route: 'gifts',
    icon: 'GiftIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Winners',
    route: 'winners',
    icon: 'DollarSignIcon',
    action: 'browes',
  },
  {
    title: 'Spin Gifts',
    route: 'spin-gifts',
    icon: 'TargetIcon',
    action: 'browes',
    resource: 'cashouts',
  },
  {
    title: 'Vouchers',
    route: 'vouchers',
    icon: 'TagIcon',
    action: 'browes',
  },
  {
    title: 'News',
    route: 'news',
    icon: 'CastIcon',
    action: 'browes',
  },
  {
    title: 'How It Works (Universal)',
    route: 'howItWorks',
    icon: 'BookOpenIcon',
    action: 'browes',
  },
  {
    title: 'ZeroCash Voucher',
    route: 'zerocashVoucher',
    icon: 'TagIcon',
    action: 'browes',
  },
  {
    title: 'Notifications',
    route: 'notifications',
    icon: 'BellIcon',
    action: 'browes',
  },

]
